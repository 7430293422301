import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout'
import { Content } from '../../components/zakia/content'


class Zakia extends Component {
  render () {
  return (
    <>
      <Layout section="articles" page="zakia">
        <Content section="articles" page="zakia"/>
      </Layout>
    </>
  )
  }
}

export default Zakia;