import zakia_img_1 from '../../images/Blind-angels-zakia-profile.png';
import zakia_img_1_mobile from '../../images/Blind-angels-zakia-profile.png';

import zakia_img_2 from '../../images/Blind-angels-zakia-video.png';
import zakia_img_2_mobile from '../../images/Blind-angels-zakia-video.png';

const fullName = "Zakia McKensey";

const copyListZakia = [
  {
    type: 'text',
    content: "Zakia McKensey is the executive director and founder of Nationz Foundation, a nonprofit in Richmond, Virginia that works to address HIV prevention and education with a specific focus on transgender women of color. The organization provides free testing for HIV and sexually transmitted infections (STIs), maintains a food pantry, runs a transitional home for transgender individuals, helps with emergency housing and rental assistance, and has two mobile testing units. Zakia is also a member of Richmond’s Human Rights Commission, where she advocates for LGBTQIA+ rights in her community."
  },
  {
    type: "image",
    content: {
      src: zakia_img_1,
      mobileSrc: zakia_img_1_mobile,
      alt: "Zakia sitting down and looking at the camera",
      caption: "Zakia McKensey"
    }
  },
  {
    type: 'text',
    content: "Zakia is featured in the third episode of <em>Blind Angels</em>, a new docuseries on HIV in the American South, produced by Courageous Studios in partnership with Gilead Sciences. The episode follows her and a colleague as they take a mobile testing unit into communities that have limited access to HIV services. In an exclusive interview with Gilead HIV, Zakia explained, “The mobile testing units give us the capability to deploy to areas where people don't have transportation. We’re getting people tested, and we’re linking them to services and care. Just about everything that we offer in the office, we are able to offer on the mobile testing units.”"
  },
  {
    type: "text",
    content: "Nationz Foundation stocks its mobile testing units—one of which has been affectionately named Deloris after Zakia’s late grandmother—with food pantry items as an added incentive for people to engage with the staff. Poverty and food insecurity are common in the South.<sup>1,2</sup> Zakia explained that having food onboard means that the people she works with have less to worry about."
  },
  {
    type: "blockquote",
    content: "In 2017, the South had a greater proportion of new HIV diagnoses (52%) than all other regions of the United States combined.<sup>1</sup>"
  },
  {
    type: "text",
    content: "As of 2019, the American South accounted for more than half (51%) of new HIV infections in the US and also had one of the highest percentages of people living with undiagnosed HIV infection, which may mean they do not access care or reach viral suppression.<sup>1,3 </sup>* Testing is an important first step in connecting people to an HIV status-neutral cycle—where those who test positive can be linked to care, and those who test negative can learn more about prevention options, such as PrEP medicine, if appropriate.<sup>4</sup>"
  },
  {
    type: "text",
    content: "In the South, in particular, transportation can be a barrier to getting tested for HIV. People in Southern counties with the highest HIV prevalence may often be at least a 50-minute drive from comprehensive, coordinated HIV healthcare.<sup>5</sup> While other testing locations may be available closer to home, some people may be unaware of them.<sup>6</sup> Other structural barriers to testing include concerns about cost and lack of insurance—highly relevant given that nearly half of uninsured people in the US live in the South as of 2019.<sup>1,6-9</sup>"
  },
  {
    type: "blockquote",
    content: "“In my experience, a lot of times individuals don’t get tested because they don’t have a way to get to the location where testing is available.”",
    author: fullName
  },
  {
    type: "text",
    content: "In addition to these structural barriers, people in the South continue to deal with HIV stigma—often based on discriminatory views of sexual orientation, substance abuse, poverty, or sex work—which remains pervasive in this area of the country. This can impact people’s willingness to engage with any HIV services.<sup>1,10</sup> They may not get tested because they don't believe they are at risk for HIV or because they fear that they'll be judged by their healthcare provider or that HIV testing reflects poorly on them as a person.<sup>6</sup>"
  },
  {
    type: "text",
    content: "Mobile health units are an important strategy for reaching underserved communities. They can help overcome structural barriers like not knowing where to go for an HIV test or not having a way to get there, and they can also help overcome the barriers related to stigma by building trust between community members and healthcare providers. While little research has been done in the US, research from other under-resourced areas of the world suggests that mobile health clinics promote patient-centered care and can be less intimidating to patients than traditional healthcare settings.<sup>11</sup>"
  },
  {
    type: "text",
    content: "Zakia can often be seen trying to encourage people to use the mobile testing unit to get tested or pick up condoms and educational materials. She understands that people may be reluctant to seek services because they may fear how others will react.<sup>6</sup> Zakia and other advocates across the South are working to eradicate these stigmatizing views, but in the meantime, she and Deloris will continue to deliver HIV services right to the people."
  },
  {
    type: "image",
    content: {
      src: zakia_img_2,
      mobileSrc: zakia_img_2_mobile,
      alt: "A woman, Zakia, holding a microphone on stage",
      caption: ""
    }
  },
]


export default copyListZakia;