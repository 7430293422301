import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from './copyList';
import References from "../global/references.js";

export const Content = (props) => {
  return (
    <div className={`${props.show ? 'd-block' : 'd-none'}`}>
      <ArticleLayout
        page={props.page}
        copyList={copyList}
        ariaLabel="A woman, Zakia, holding a microphone on stage"
        videoStillAlt="A woman, Zakia, holding a microphone on stage"
        subheading="Mobile Health Units Bring HIV Testing to Underserved Communities IN THE AMERICAN SOUTH"
        noUpperTitle={ true }
        heading="A CONVERSATION WITH NATIONZ FOUNDATION EXECUTIVE DIRECTOR ZAKIA McKENSEY"
        publishedDate='March 25, 2022'
        minuteRead='4'
      />
      <References page={props.page} />
    </div>
  )
}

